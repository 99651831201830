import logo from './logo.svg';
import './App.css';
import Home from './Home';
import Navigation from './Navigation';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import Footer from './Footer';
import Fast_Track_Knee_Replacement_by_Dr_Srinivas_Polisetty_orthopedic_Doctor_in_Guntur from './Component/Services/Fast_Track_Knee_Replacement_by_Dr_Srinivas_Polisetty_orthopedic_Doctor_in_Guntur';
import Therapy_Free_Total_Knee_Replacement_by_Dr_Srinivas_Polisetty_orthopedic_Doctor_in_Guntur from './Component/Services/Therapy_Free_Total_Knee_Replacement_by_Dr_Srinivas_Polisetty_orthopedic_Doctor_in_Guntur';
import Revision_Total_Knee_Replacement_Surgery_by_Dr_Srinivas_Polisetty_In_Guntur from './Component/Services/Revision_Total_Knee_Replacement_Surgery_by_Dr_Srinivas_Polisetty_In_Guntur';
import Unicompartmental_Knee_Replacement_by_Dr_Srinivas_Polisetty_orthopedic_Doctor_in_Guntur from './Component/Services/Unicompartmental_Knee_Replacement_by_Dr_Srinivas_Polisetty_orthopedic_Doctor_in_Guntur';
import Arthroscopy_In_guntur from'./Component/Services/arthroscopy_In_guntur';
import Spine_Surgery_In_Guntur from'./Component/Services/Spine_Surgery_In_Guntur';
import Fracture_management_guntur from'./Component/Services/fracture_management_guntur';
import Total_Knee_Replacement_Guntur from'./Component/Services/Total_Knee_Replacement_Guntur';
import TKR_in_Severe_Deformity_And_Bone_Loss from'./Component/Services/TKR_in_Severe_Deformity_And_Bone_Loss';
import Ligament_Repairs_or_Sports_Injuries_Treatment_by_Dr_Srinivas_Polisetty_Orthopedic_Doctor_in_Guntur from'./Component/Services/Ligament_Repairs_or_Sports_Injuries_Treatment_by_Dr_Srinivas_Polisetty_Orthopedic_Doctor_in_Guntur';
import Polytrauma_Treatment_In_Guntur from'./Component/Services/Polytrauma_Treatment_In_Guntur';
import Blogs from './Component/Blogs';
import Gallery from './Component/Gallery';
import Regenerative_knee_treatment from './Component/Services/Regenerative_knee_treatment';
import Fast_track_hip_joint_replacement_in_Guntur from './Component/Services/Fast_track_hip_joint_replacement_in_Guntur';
import Social_Media from './Component/Social_Media';
import Dr_Srinivas_Polisetty_Best_orthopedic_Doctor_in_Guntur from './Component/Dr_Srinivas_Polisetty_Best_orthopedic_Doctor_in_Guntur';
import EXPERTISE from './Component/EXPERTISE';


function App() {
  return (
    <>
      <BrowserRouter>
        <Navigation />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/Home" element={<Home />} />
          <Route path="/Fast_Track_Knee_Replacement_by_Dr_Srinivas_Polisetty_orthopedic_Doctor_in_Guntur" element={<Fast_Track_Knee_Replacement_by_Dr_Srinivas_Polisetty_orthopedic_Doctor_in_Guntur/>} />
          <Route path="/Therapy_Free_Total_Knee_Replacement_by_Dr_Srinivas_Polisetty_orthopedic_Doctor_in_Guntur" element={<Therapy_Free_Total_Knee_Replacement_by_Dr_Srinivas_Polisetty_orthopedic_Doctor_in_Guntur/>} />
          <Route path='/Revision_Total_Knee_Replacement_Surgery_by_Dr_Srinivas_Polisetty_In_Guntur' element={<Revision_Total_Knee_Replacement_Surgery_by_Dr_Srinivas_Polisetty_In_Guntur/>}/>
          <Route path='/Unicompartmental_Knee_Replacement_by_Dr_Srinivas_Polisetty_orthopedic_Doctor_in_Guntur' element={<Unicompartmental_Knee_Replacement_by_Dr_Srinivas_Polisetty_orthopedic_Doctor_in_Guntur/>}/>
          <Route path='/Arthroscopy_In_guntur' element={<Arthroscopy_In_guntur/>}/>
          <Route path='/Spine_Surgery_In_Guntur' element={<Spine_Surgery_In_Guntur/>}/>
          <Route path='/Fracture_management_guntur' element={<Fracture_management_guntur/>}/>
          <Route path='/Total_Knee_Replacement_Guntur' element={<Total_Knee_Replacement_Guntur/>}/>
          <Route path='/TKR_in_Severe_Deformity_And_Bone_Loss' element={<TKR_in_Severe_Deformity_And_Bone_Loss/>}/>
          <Route path='/Ligament_Repairs_or_Sports_Injuries_Treatment_by_Dr_Srinivas_Polisetty_Orthopedic_Doctor_in_Guntur' element={<Ligament_Repairs_or_Sports_Injuries_Treatment_by_Dr_Srinivas_Polisetty_Orthopedic_Doctor_in_Guntur/>}/>
          <Route path='/Polytrauma_Treatment_In_Guntur' element={<Polytrauma_Treatment_In_Guntur/>}/>
          <Route path='/Blogs' element={<Blogs/>}/>
          <Route path='/Gallery' element={<Gallery/>}/>
          <Route path='/Regenerative_knee_treatment' element={<Regenerative_knee_treatment/>}/>
          <Route path='/Fast_track_hip_joint_replacement_in_Guntur' element={<Fast_track_hip_joint_replacement_in_Guntur/>}/>
          <Route path='/Social_Media' element={<Social_Media/>}/>
          <Route path='/Dr_Srinivas_Polisetty_Best_orthopedic_Doctor_in_Guntur' element={<Dr_Srinivas_Polisetty_Best_orthopedic_Doctor_in_Guntur/>}/>
          <Route path='/EXPERTISE' element={<EXPERTISE/>}/>
          <Route path="*" element={<Home />} />
        </Routes>
        <Footer/>
      </BrowserRouter>
    </>
  );
}

export default App;
