import React from 'react'

const Gallery = () => {
    return (
        <>
            <div className='Service_Banner'>
                <div className='container'>
                    <h1>Gallery</h1>
                </div>
            </div>
            <div className='container'>
                <div className='row'>

                    <div className='col-md-4 col-sm-12 mt-2 mb-2'>
                        <div className='card'>
                            <img src='news5.jpg' alt='' style={{width:'100%'}}></img>
                        </div>
                    </div>

                    <div className='col-md-4 col-sm-12 mt-2 mb-2'>
                        <div className='card'>
                            <img src='news4.jpg' alt='' style={{width:'100%'}}></img>
                        </div>
                    </div>

                    <div className='col-md-4 col-sm-12 mt-2 mb-2'>
                        <div className='card'>
                            <img src='news3.jpg' alt='' style={{width:'100%'}}></img>
                        </div>
                    </div>

                    <div className='col-md-4 col-sm-12 mt-2 mb-2'>
                        <div className='card'>
                            <img src='news2.jpg' alt='' style={{width:'100%'}}></img>
                        </div>
                    </div>
                    
                </div>

            </div>
        </>
    )
}

export default Gallery