import React from 'react'
import videoBg from '../assets/KNR Full.mp4';

const Video = () => {
    return (
        <div className='videoview shadow-sm  bg-body rounded'>
            <div className="overlay"></div>
            <video src={videoBg} autoPlay loop muted />
            <div className="bg-text">
                <h1>Your knees are</h1>
                <p>Our Priority</p>
            </div>
            {/* <div className="content">
                <h1>Welcome</h1>
                <p>To my site.</p>
            </div> */}
        </div>
    )
}

export default Video