import React from 'react'
import { FaHome } from "react-icons/fa";
import { FaMobile } from "react-icons/fa";
import { BsTelephoneInboundFill } from "react-icons/bs";
import { FaLocationDot } from "react-icons/fa6";

const Footer = () => {
    return (
        <>
            <div className='Footer'>
                <div className='container'>
                    <div className='row'>
                        
                        <div className='col-md-4 col-sm-12'>
                            <img src='website profile1.png' alt='Dr Srinivas polisetty' style={{width:'100%'}}></img>
                        </div>
                        <div className='col-md-4 col-sm-12'>
                            <div>
                                <FaLocationDot  style={{color:'#a92525'}}/>
                                <p style={{textAlign:'justify'}}>Address: 15-11-154 Near Best Price Mangalagiri Road, Vedanta hospital, Guntur, Andhra Pradesh 522001</p>
                            </div>
                        </div>
                        <div className='col-md-4 col-sm-12'>
                            
                        <BsTelephoneInboundFill  style={{color:'#a92525'}}/>
                            <p className='mt-2'>Contact : +91 77606 03287 / 9989678783</p>
                            {/* <p className=''>Contact : +91 9989678783</p> */}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Footer