import React from 'react'

const DoctorContent = () => {
    return (
        <>
            <div className="doctorcontent">
                <div className='layer'>
                    <div className='container'>
                        <div className='row'>
                        <div className='col-md-6 col-sm-12 details'>
                                <h1 style={{color:'#e7bb2a'}}>Orthopedic Surgeon In Guntur</h1>
                                <h5 className='name'>Meet Dr. Srinivas Polisetty – Your Expert in Fast-Track Joint Replacement Surgery.</h5>
                                <p>Looking for the best orthopedic surgeon in Guntur or Hyderabad? Dr. Srinivas Polisetty is a leading name in fast-track joint replacement surgery, renowned for helping patients walk confidently—without walkers, sticks, or support—just two days after surgery. With a focus on rapid recovery and advanced techniques, Dr. Srinivas is redefining knee and hip care, making pain-free movement a reality for countless patients.</p>
                                <a href='Dr_Srinivas_Polisetty_Best_orthopedic_Doctor_in_Guntur' id='button'><button type="button" class="btn btn-outline-warning mb-5">SEE BIO</button></a>
                            </div>
                            <div className='col-md-6 col-sm-12 details1'>
                                <img src='Polisetty srinivas.png' alt='doctor image'></img>
                            </div>
                          
                        </div>
                    </div>
                </div>

            </div>

        </>
    )
}

export default DoctorContent