import React, { useState } from 'react'
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import './NavbarStyles.css'
import Nav from 'react-bootstrap/Nav';
import { Dropdown, DropdownButton, NavDropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { DropdownSubmenu, NavDropdownMenu } from "react-bootstrap-submenu";
const Navigation = () => {
    const [links, setLinks] = useState(false);
    const submenuLinks = () => {
        setLinks(!links)
    }
    // for scrolling styles

    const [navchange, setNavbar] = useState(false);
    const changebackground = () => {
        if (window.scrollY >= 150) {
            setNavbar(true)
        }
        else {
            setNavbar(false)
        }
    }

    window.addEventListener('scroll', changebackground)
    return (
        <>
            <div className='text-center main_logo'>
                <div className='container'>
                    <img src='website profile1.png'></img>
                </div>
            </div>

            <div className='displayOnPc'>
                <Navbar collapseOnSelect expand="lg" className={navchange ? "active bg-body-tertiary navbar shadow-sm p-2 mb-5 bg-body rounded fixed-top" : "notfixed bg-body-tertiary navbar shadow-sm p-2 mb-5 bg-body rounded"}>
                    <Container>
                        <img src='website profile1.png' alt='' className=''></img>
                        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                        <Navbar.Collapse id="responsive-navbar-nav">
                            <Nav className="" navbarScroll>
                                <Nav.Link href="Home" className='navitems'>HOME</Nav.Link>
                                <Nav.Link href="Dr_Srinivas_Polisetty_Best_orthopedic_Doctor_in_Guntur" className='navitems'>ABOUT US</Nav.Link>
                                <Dropdown className='dropmenu'>
                                    <Dropdown.Toggle variant="" className='dropdown'>
                                        SERVICES
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu className="mt-3" id='Submenu'>
                                    <Dropdown.Item href="Therapy_Free_Total_Knee_Replacement_by_Dr_Srinivas_Polisetty_orthopedic_Doctor_in_Guntur" className='galleryitemed'>Therapy-Free Total Knee Replacement</Dropdown.Item>
                                        <Dropdown.Item href="Fast_Track_Knee_Replacement_by_Dr_Srinivas_Polisetty_orthopedic_Doctor_in_Guntur" className='galleryitemed'>Fast-Track Knee Replacement</Dropdown.Item>
                                        <Dropdown.Item href="Revision_Total_Knee_Replacement_Surgery_by_Dr_Srinivas_Polisetty_In_Guntur" className='galleryitemed'>Revision TKR</Dropdown.Item>
                                        {/* <Dropdown.Item href="Unicompartmental_Knee_Replacement_by_Dr_Srinivas_Polisetty_orthopedic_Doctor_in_Guntur" className='galleryitemed'>Unicompartmental Knee Replacement</Dropdown.Item> */}
                                        <Dropdown.Item href="Arthroscopy_In_guntur" className='galleryitemed'>Arthroscopy (Key Hole Surgery)</Dropdown.Item>
                                        <Dropdown.Item href="Regenerative_knee_treatment" className='galleryitemed'>Regenerative knee treatment</Dropdown.Item>
                                        <Dropdown.Item href="Fracture_management_guntur" className='galleryitemed'>Fracture Management</Dropdown.Item>
                                        {/* <Dropdown.Item href="Total_Knee_Replacement_Guntur" className='galleryitemed'>Simple & Complex Primary Total Knee Replacement(TKR)</Dropdown.Item> */}
                                        {/* <Dropdown.Item href="TKR_in_Severe_Deformity_And_Bone_Loss" className='galleryitemed'>TKR in Severe Deformity & Bone Loss</Dropdown.Item> */}
                                        {/* <Dropdown.Item href="Ligament_Repairs_or_Sports_Injuries_Treatment_by_Dr_Srinivas_Polisetty_Orthopedic_Doctor_in_Guntur" className='galleryitemed'>Ligament Repairs in Knee & Shoulder (Sports Injuries)</Dropdown.Item> */}
                                        <Dropdown.Item href="Fast_track_hip_joint_replacement_in_Guntur" className='galleryitemed'>Fast Track Hip Joint Replacement</Dropdown.Item>
                                        
                                    </Dropdown.Menu>

                                </Dropdown>

                                <Nav.Link href="EXPERTISE" className='navitems'>EXPERTISE</Nav.Link>
                                <Nav.Link href="Gallery" className='navitems'>GALLERY</Nav.Link>
                                <Nav.Link href="Blogs" className='navitems'>BLOGS</Nav.Link>
                                <Nav.Link href="Social_Media" className='navitems'>SOCIAL MEDIA</Nav.Link>
                                <Nav.Link href="tel: 9989678783" className="navitems">CONTACT</Nav.Link>

                            </Nav>

                        </Navbar.Collapse>
                    </Container>
                </Navbar>
            </div>

            <div id='navbarDisplay'>
                <Navbar collapseOnSelect expand="lg" className="bg-body-tertiary navbar shadow-sm p-2 mb-5 bg-body rounded fixed-top">
                    <Container>
                        <img src='website profile1.png' alt='' className=''></img>
                        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                        <Navbar.Collapse id="responsive-navbar-nav">
                            <Nav className="navlinks" navbarScroll>
                                <Nav.Link href="Home" className='navitems'>HOME</Nav.Link>
                                <Nav.Link href="Dr_Srinivas_Polisetty_Best_orthopedic_Doctor_in_Guntur" className='navitems'>ABOUT US</Nav.Link>
                                <Dropdown className='dropmenu'>
                                    <Dropdown.Toggle variant="" className='dropdown'>
                                        SERVICES
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu className="mt-2" id='Submenu'>
                                    <Dropdown.Item href="Therapy_Free_Total_Knee_Replacement_by_Dr_Srinivas_Polisetty_orthopedic_Doctor_in_Guntur" className='galleryitemed'>Therapy-Free Total Knee Replacement</Dropdown.Item>
                                        <Dropdown.Item href="Fast_Track_Knee_Replacement_by_Dr_Srinivas_Polisetty_orthopedic_Doctor_in_Guntur" className='galleryitemed'>Fast-Track Knee Replacement</Dropdown.Item>
                                        <Dropdown.Item href="Revision_Total_Knee_Replacement_Surgery_by_Dr_Srinivas_Polisetty_In_Guntur" className='galleryitemed'>Revision TKR</Dropdown.Item>
                                        {/* <Dropdown.Item href="Unicompartmental_Knee_Replacement_by_Dr_Srinivas_Polisetty_orthopedic_Doctor_in_Guntur" className='galleryitemed'>Unicompartmental Knee Replacement</Dropdown.Item> */}
                                        <Dropdown.Item href="Arthroscopy_In_guntur" className='galleryitemed'>Arthroscopy (Key Hole Surgery)</Dropdown.Item>
                                        <Dropdown.Item href="Regenerative_knee_treatment" className='galleryitemed'>Regenerative knee treatment</Dropdown.Item>
                                        <Dropdown.Item href="Fracture_management_guntur" className='galleryitemed'>Fracture Management</Dropdown.Item>
                                        {/* <Dropdown.Item href="Total_Knee_Replacement_Guntur" className='galleryitemed'>Simple & Complex Primary Total Knee Replacement(TKR)</Dropdown.Item> */}
                                        {/* <Dropdown.Item href="TKR_in_Severe_Deformity_And_Bone_Loss" className='galleryitemed'>TKR in Severe Deformity & Bone Loss</Dropdown.Item> */}
                                        {/* <Dropdown.Item href="Ligament_Repairs_or_Sports_Injuries_Treatment_by_Dr_Srinivas_Polisetty_Orthopedic_Doctor_in_Guntur" className='galleryitemed'>Ligament Repairs in Knee & Shoulder (Sports Injuries)</Dropdown.Item> */}
                                        <Dropdown.Item href="Fast_track_hip_joint_replacement_in_Guntur" className='galleryitemed'>Fast Track Hip Joint Replacement</Dropdown.Item>
                                        
                                    </Dropdown.Menu>

                                </Dropdown>

                                <Nav.Link href="EXPERTISE" className='navitems'>EXPERTISE</Nav.Link>
                                <Nav.Link href="Gallery" className='navitems'>GALLERY</Nav.Link>
                                <Nav.Link href="Blogs" className='navitems'>BLOGS</Nav.Link>
                                <Nav.Link href="Social_Media" className='navitems'>SOCIAL MEDIA</Nav.Link>
                                <Nav.Link href="tel: 077606 03287" className="navitems">CONTACT</Nav.Link>

                            </Nav>

                        </Navbar.Collapse>
                    </Container>
                </Navbar>
            </div>


            {/* <Navbar collapseOnSelect expand="lg" className="bg-body-tertiary">

                <Container>
                    <Navbar.Brand href="#"><img src='HospitalLogo.png'></img></Navbar.Brand>
                    <Navbar.Toggle/>
                        <Navbar.Collapse >
                            <Nav
                                style={{ maxHeight: '100px' }}
                                navbarScroll
                            >

                                <ul className='NavbarLinks text-center'>
                                    <li><a href=''>Home</a></li>
                                    <li><a href=''>About Us</a></li>
                                    <li><a href='' onMouseOver={() => { setLinks(false) }}>Expertise</a></li>
                                    <li><a href='' onMouseOver={submenuLinks}>Services</a>
                                        <div onMouseLeave={submenuLinks}>
                                            <ul className={links ? 'subNavLinks' : 'SubLinks'}>
                                                <li><a href=''>link1link1link1link1link1link1link1</a></li>
                                                <li><a href=''>Link2</a></li>
                                                <li><a href=''>Link3</a></li>
                                                <li><a href=''>Link4</a></li>
                                            </ul>
                                        </div>
                                    </li>
                                    <li><a href='' onMouseOver={() => { setLinks(false) }}>Gallery</a></li>
                                    <li><a href=''>Blogs</a></li>
                                    <li><a href=''>Social Media</a></li>
                                    <li><a href=''>Contact</a></li>
                                </ul>
                            </Nav>
                        </Navbar.Collapse>
                </Container>
            </Navbar> */}

        </>
    )
}

export default Navigation